import logo from "./logo.svg";
import "./App.css";

const LightPaper = require("./assets/lightpaper.pdf");

function App() {
  return (
    <div>
      <div className="absolute top-0 left-0 w-full py-5">
        <img src="/img/logo.png" alt="" className="m-auto w-32 sm:w-32 " />
      </div>
      <div className="port_bg">
        <div className="n-container">
          <div className="flex justify-start sm:items-center items-end h-screen">
            <div className="sm:w-1/2 max-w-[500px]">
              <div className="relative">
                <p className="absolute text-white font-bold text-4xl mb-10 sm:mb-0 text-center sm:text-start sm:text-6xl filter grayscale blur-md contrast-200">
                  We are the Royalty Business
                </p>
                <p className="text-white font-bold text-4xl mb-10 sm:mb-0 text-center sm:text-start sm:text-6xl">
                  We are the Royalty Business
                </p>
              </div>
              <p className="text-white text-center sm:text-start font-normal mt-5 hidden sm:block">
                At RoyaltyX your royalties from your intellectual property and
                commercial perpetuity can be derived economically and fiscally
                with its own real life utility. We will work with you to derive,
                utalize, and create real life value through the blockchain and
                it’s real life derivatives.
                <br />
                <br />
                Creating this value enables your royalties to unleash newfound
                utility for your consumers and fanbase while also acting as a
                innovative way to derive its utility through our royalty
                derivative exchange where users can buy, sell, lease, trade,
                bid, and derive value through your intellectual property and
                commercial perpetuity.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black">
        <div className="py-5 block sm:hidden">
          <p className="text-white text-center sm:text-start font-normal mt-5 px-4 text-lg">
            At RoyaltyX your royalties from your intellectual property and
            commercial perpetuity can be derived economically and fiscally with
            its own real life utility. We will work with you to derive, utalize,
            and create real life value through the blockchain and it’s real life
            derivatives.
            <br />
            <br />
            Creating this value enables your royalties to unleash newfound
            utility for your consumers and fanbase while also acting as a
            innovative way to derive its utility through our royalty derivative
            exchange where users can buy, sell, lease, trade, bid, and derive
            value through your intellectual property and commercial perpetuity.
          </p>
        </div>
        <div className="n-container py-10 sm:py-20">
          <div>
            <img src="/img/logo.png" alt="" className="m-auto w-32 sm:w-32 " />
            <a
              href="https://discord.com/channels/1003913696466190376/1003931477542703154/1004734163947098213"
              target={"_blank"}
              without
              rel="noreferrer"
            >
              <p className="border-white border text-white text-2xl w-max text-center m-auto mt-6 px-12 py-2 rounded-lg bg-transparent">
                Join our discord
              </p>
            </a>
            <p className="text-white text-lg sm:text-2xl font-normal text-center mt-5">
              Join our discord to create your own decentralized label,
              distribution company, publishing company, production company,
              public relations company, media company, and entertainment
              management company and much more.
            </p>

            <p className="text-white text-lg sm:text-2xl font-normal text-center mt-3">
              Are you looking to network in these industries? Join our discord
              now!
            </p>
          </div>

          <div className="sm:flex justify-between gap-6 my-20 items-center">
            <img src="/img/Mask_group.png" alt="" className="w-full sm:w-1/2" />
            <div>
              <p className="text-3xl sm:text-5xl font-bold text-center text-white">
                Beyond your wildest dreams
              </p>
              <p className="text-white text-center text-lg sm:text-2xl font-normal mt-5">
                The founders can work directly with you to engage real life
                value through your commerical perpetuity and intellectual
                property by unleashing it’s infinite potential through
                blockchain solution.
              </p>
            </div>
          </div>

          <div className="py-10 sm:py-20">
            <p className="text-4xl sm:text-5xl text-center text-white font-bold">
              RoyaltyX NFT’s
            </p>
            <div className="grid grid-cols-3 sm:grid-cols-7 gap-3 sm:gap-4 w-full mt-10">
              <div className="w-full">
                <img src="/img/nfts/nft1.png" alt="" className="w-full" />
              </div>
              <div className="w-full">
                <img src="/img/nfts/nft2.png" alt="" className="w-full" />
              </div>
              <div className="w-full">
                <img src="/img/nfts/nft3.png" alt="" className="w-full" />
              </div>
              <div className="w-full">
                <img src="/img/nfts/nft4.png" alt="" className="w-full" />
              </div>
              <div className="w-full">
                <img src="/img/nfts/nft5.png" alt="" className="w-full" />
              </div>
              <div className="w-full">
                <img src="/img/nfts/nft6.png" alt="" className="w-full" />
              </div>
              <div className="w-full">
                <img
                  src="/img/nfts/nft7.png"
                  alt=""
                  className="w-full hidden sm:block"
                />
              </div>
            </div>
            <p className="text-white text-center font-normal text-xl sm:text-2xl mt-5">
              Join our discord to hear about RoyaltyX NFT’s utilities and value!
            </p>
          </div>

          <div className="py-10 sm:py-20 text-center">
            <p className="text-4xl sm:text-5xl text-center text-white font-bold">
              Get to know Us
            </p>
            <div className="sm:flex justify-center items-center gap-10 mt-10">
              <a href={LightPaper} target={"_blank"}>
                <img
                  src="/img/litepapaer.png"
                  alt=""
                  className="w-48 m-auto sm:m-0"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <img src="/img/lin_bg.png" alt="" className="w-full" /> */}
      {/* <div className="bg-black py-5 border-t border-white/10">
        <img src="/img/logo.png" alt="" className="m-auto w-32 sm:w-32 " />
      </div> */}
    </div>
  );
}

export default App;
